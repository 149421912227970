import {ConfirmationModal} from '../components/modals/confirmation';
import ReactDOM from 'react-dom/client';
import {AlertModal} from '../components/modals/alert';

let showing = false;


export const showConfirmation = ({message}: {message: string}): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (showing) {
            reject('Modal already showing.');
            return;
        }
        const root = ReactDOM.createRoot(
            document.getElementById('modal') as HTMLElement
        );
        const confirmation = ConfirmationModal({
            onConfirm: () => {
                root.unmount();
                showing = false;
                resolve(true);
            },
            onCancel: () => {
                root.unmount();
                showing = false;
                resolve(false);
            },
            message
        });
        root.render(confirmation);
    });


}

export const showAlert = ({message, type = 'error'}: {message: string, type?: 'error' | 'warning' | 'success'}): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (showing) {
            reject('Modal already showing.');
            return;
        }
        const root = ReactDOM.createRoot(
            document.getElementById('modal') as HTMLElement
        );
        const alert = AlertModal({
            onClose: () => {
                root.unmount();
                showing = false;
                resolve()
            },
            message,
            type
        });
        root.render(alert);
    });


}