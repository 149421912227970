import {useCallback, useState, ChangeEvent, forwardRef, useImperativeHandle, useMemo, useRef} from "react"

export const PasswordInput = forwardRef(({name, className, required, onError = () => {}, onChange = () => {}}: {name: string, className?: 'string', required?: boolean, onError?: (name: string, errors: string[]) => void, onChange?: (name: string, text: string) => void}, ref) => {
    const [value, setValue] = useState<string>('');
    const valueRef = useRef<string>('');
    const [hasError, setHasError] = useState<boolean>(required ? true : false);
    useImperativeHandle(ref, () => ({
        isValid: () => validate(valueRef.current).length === 0,
        getValue: () => valueRef.current
    }));
    const updateValue = (val: string) => {
        valueRef.current = val;
        setValue(val);
    };
    const onValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onError(name, []);
        let val = event.target.value;
        updateValue(val);
    }, []);
    const validate = useCallback((text: string) => {
        const err = [];
        if (!text && required) err.push('Password required');
        setHasError(err.length > 0);
        onChange(name, text);
        return err;
    }, []);
    const onBlur = useCallback(() => {
        onError(name, validate(valueRef.current));
    }, [])
    return <input name={name} type="password" className={`${className}  ${hasError ? ' input-error' : ''}`} onBlur={onBlur} value={value} onChange={onValueChange} />
})