import {useCallback, useState, ChangeEvent, forwardRef, useImperativeHandle, useMemo, useRef, LegacyRef} from "react"

export const EmailInput = forwardRef(({name, className, required, onError = () => {}, onChange = () => {}}: {name: string, className?: 'string', required?: boolean, onError?: (name: string, errors: string[]) => void, onChange?: (name: string, text: string) => void}, ref) => {
    const [value, setValue] = useState<string>('');
    const valueRef = useRef<string>('');
    const inputRef = useRef<any>();
    const [hasError, setHasError] = useState<boolean>(required ? true : false);
    useImperativeHandle(ref, () => ({
        isValid: () => validate(valueRef.current).length === 0,
        getValue: () => valueRef.current,
        focus: () => {inputRef.current.focus()}
    }));
    const updateValue = (val: string) => {
        valueRef.current = val;
        setValue(val);
    };
    const onValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onError(name, []);
        let val = event.target.value;
        //DO MUTATIONS
        val = val.replace(/[^0-9a-zA-Z\@\-\_\.]/, '');
        updateValue(val);
    }, []);
    const validate = useCallback((text: string) => {
        const err = [];
        if (!text && required) err.push('Email required');
        else if (text && !/^[0-9A-Za-z\.\-\_]+@[0-9A-Za-z\.\_\-]+\.[A-Za-z]+$/.test(text ?? '')) err.push('Email in wrong format');
        else if (text && !/apyre.com$/.test(text ?? '')) err.push('Please use your apyre.com email');
        setHasError(err.length > 0);
        onChange(name, text);
        return err;
    }, []);
    const onBlur = useCallback(() => {
        onError(name, validate(valueRef.current));
    }, [])
    return <input ref={inputRef} name={name} className={`${className}  ${hasError ? ' input-error' : ''}`} onBlur={onBlur} value={value} onChange={onValueChange} />
})