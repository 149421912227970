export class InputValidation {
    public name: string;
    public hasError: boolean;
    public messages: string[];
    constructor(name: string, hasError: boolean, messages: string[] = []) {
        this.name = name;
        this.hasError = hasError;
        this.messages = messages;
    }
}


export const caseValidator = (controlName: string, value: any): InputValidation => {
    const messages = [];
    switch (controlName) {
        case 'contactFirstName':
            if (isEmpty(value)) messages.push(`Contact's first name is required.`);
            break;
        case 'contactZipcode':
            if (isEmpty(value)) messages.push(`Contact's zipcode is required.`);
            if (value && !isZipcode(value)) messages.push(`Contact's zipcode is in the wrong format.`);
            break;
        case 'contactState':
            if (isEmpty(value)) messages.push(`Contact's state is required.`);
            if (value && !isState(value)) messages.push(`Contact's state is incorrect.`);
            break;
        case 'contactPhone':
            if (isEmpty(value)) messages.push(`Contact's phone is required.`);
            if (value && !isPhoneNumber(value)) messages.push(`Contact's phone number is incorrect.`);
            break;
        case 'contactEmail':
            if (isEmpty(value)) messages.push(`Contact's email is required.`);
            if (value && !isEmail(value)) messages.push(`Contact's email address is incorrect.`);
            break;

    }
    return new InputValidation(controlName, messages.length > 0, messages);
}

// CHECKS

function isEmpty(value: any) {
    return (value === '' || value === undefined || value === null);
}

function lessThanMinimumCharacters(numOfChars: number = 1, value: string) {
    return isEmpty(value) || value.length < numOfChars;
}

function moreThanMaximunCharacters(numOfChars: number = 1, value: string) {
    return isEmpty(value) || value.length < numOfChars;
}

function isZipcode(value: string) {
    return /^[0-9]{5,5}$/.test(value)
}

function isState(value: string) {
    return ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'].indexOf(value) > -1;
}

function isPhoneNumber(value: string) {
    return value.replace(/[^0-9]/g, '').length === 10;
}

function isEmail(value: string) {
    return /.+@.+\..+/.test(value);
}

