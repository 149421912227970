import {PropsWithChildren} from "react"

export const Group = ({label, children, id}: {label: string, id: string} & PropsWithChildren) => {
    return <div style={{
        fontSize: '13px',
        flex: '1 1 auto',
        position: 'relative',
        border: '1px solid #555',
        padding: '1.5em 1em 1em 1em',
        margin: '16px 8px 8px 8px',
        boxSizing: 'border-box',
        borderRadius: '.5em',
        backgroundColor: '#222223'
    }}>
        {children}
        < div id={id} style={{position: 'absolute', top: '-1em', backgroundColor: "#333", padding: '.2em 5px', color: 'gray', borderRadius: '5px', border: '1px solid gray'}}> {label}</div >
    </div >
}