import {ChangeEvent, useCallback, useEffect, useState} from "react";
import '../../styles/liveInput.css';
import {caseValidator, InputValidation} from "../../utilities/caseValidator";
import {format} from "../../utilities/fieldFormatter";
import {FocusIndicator} from "./FocusIndicator";

export const LiveTextInput = ({value, fieldName, label, update = () => {}, onValidation = () => {}, width}: {value: string | undefined, fieldName: string, label: string, update: (fieldName: string, value: any) => void, onValidation?: (result: InputValidation) => void, width?: string}) => {
    const [inputValue, setInputValue] = useState(value ?? '');
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        validate(value);
    }, [value]);
    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        validate(format(fieldName, event.target.value));
    }, []);
    const validate = useCallback((val: string | undefined) => {
        let text = val;
        if (text) {
            const words = text.replace(/\s+/, " ").split(/(\s+)/);
            for (let i = 0; i < words.length; i++) {
                if (!words[i][0]) continue;
                words[i] = words[i][0].toUpperCase() + words[i].substring(1);
            }
            text = words.join("");
        }
        setInputValue(text ?? '');
        const validation = caseValidator(fieldName, text ?? '');
        onValidation(validation);
        setHasError(validation.hasError);
    }, []);
    const onBlur = useCallback(() => {!hasError && update(fieldName, format(fieldName, inputValue.trim()))}, [inputValue])
    return <div className="live-input-label-container" style={{width, flex: width ? '0 0 auto' : '1 1 auto'}}>
        <h4 className="live-input-label">{label}</h4>
        <div className={`live-input ${hasError ? 'has-error' : ''}`}>
            <input style={{flex: '1 1 auto', width: 0}} name={fieldName} value={inputValue ?? ''} onChange={onChange} onBlur={onBlur} required />
            <FocusIndicator />
        </div>
    </div>
}