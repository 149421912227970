import {KeyboardEventHandler, useCallback, useEffect, useRef, useState} from "react"
import {doc, onSnapshot, updateDoc} from "firebase/firestore";
import {db} from '../utilities/firebase';
import {LiveTextInput} from "./controls/LiveTextInput";
import {LoadingOverlay} from "./LoadingOverlay";
import {InputValidation} from "../utilities/caseValidator";
import {Group} from "./controls/Group";
import {Row} from "./flex/Row";
import {Flex} from "./flex/Flex";
import "../styles/caseview.css";


export const CaseView = ({caseId}: {caseId: string}) => {
    const document = useRef(doc(db, "cases", caseId)).current;
    const [data, setData] = useState<any>({});
    const [inputErrors, setInputErrors] = useState<{[key: string]: string[]}>({});
    useEffect(() => {
        const unsub = onSnapshot(document, (doc) => {
            setData(doc.data());
        });
        return () => {unsub()}
    }, []);
    const update = useCallback((fieldName: string, value: any) => {
        updateDoc(document, fieldName, value);
    }, []);
    const onValidation = useCallback((result: InputValidation) => {
        setInputErrors(obj => {
            if (result.hasError) {
                obj[result.name] = result.messages;
            } else {
                delete obj[result.name]
            }
            return obj;
        });
    }, []);
    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === 'Enter') {
            const currentTarget = e.currentTarget as HTMLElement;
            const fields =
                Array.from(currentTarget.querySelectorAll('input')) ||
                []
            const position = fields.indexOf(
                e.target as HTMLInputElement
            )
            if (fields[position + 1]) {
                fields[position + 1].focus();
            } else if (fields[0]) {
                fields[0].focus();
            }
        }
    }
    return data ? <div onKeyDown={onKeyDown}>
        <form noValidate>
            <div className="reactive-column-container">
                <div className="reactive-column">
                    <Group label="Case Info" id="caseinfo">
                        <Row>
                            <Flex>
                                Inquiry #{data?.inquiryNumber ?? 'Unassigned'}
                            </Flex>
                            <Flex>
                                Case #{data?.caseNumber ?? 'Unassigned'}
                            </Flex>
                            <Flex>
                                Dispatched: {data?.dispatched ? 'YES' : 'NO'}
                            </Flex>
                        </Row>
                        <Row>
                            <Flex>
                                <LiveTextInput value={data.contactPhone} fieldName="contactPhone" label="Caller Phone" update={update} onValidation={onValidation} />
                            </Flex>
                            <Flex>
                                <LiveTextInput value={data.contactFirstName} fieldName="contactFirstName" label="Caller First Name" update={update} onValidation={onValidation} />
                            </Flex>
                            <Flex>
                                <LiveTextInput value={data.contactLatName} fieldName="contactLastName" label="Caller Last Name" update={update} onValidation={onValidation} />
                            </Flex>
                        </Row>
                        <Row>
                            <Flex>
                                <LiveTextInput value={data.decedentFirstName} fieldName="decedentFirstName" label="Decedent First Name" update={update} onValidation={onValidation} />
                            </Flex>
                            <Flex>
                                <LiveTextInput value={data.decedentMiddleName} fieldName="decedentMiddleName" label="Decedent Middle Name" update={update} onValidation={onValidation} />
                            </Flex>
                            <Flex>
                                <LiveTextInput value={data.decedentLastName} fieldName="decedentLastName" label="Decedent Last Name" update={update} onValidation={onValidation} />
                            </Flex>
                        </Row>
                    </Group>
                </div>
                <div className="reactive-column">
                    <ul className="quick-links buttons">
                        <li>Email Quote</li>
                        <li>Email Checklist</li>
                        <li>Print Face Sheet</li>
                    </ul>
                    <ul className="quick-links">
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="#contact">Service Address</a></li>
                        <li><a href="#contact">Employer</a></li>
                        <li><a href="#contact">Next of Kin</a></li>
                        <li><a href="#contact">Billing</a></li>
                        <li><a href="#contact">Shipping</a></li>
                        <li><a href="#contact">Payment</a></li>
                        <li><a href="#contact">Products</a></li>
                        <li><a href="#contact">Fees</a></li>
                        <li><a href="#errors">Errors</a></li>
                        <li><a href="#contact">Case Logs</a></li>
                        <li><a href="#contact">Obituary</a></li>
                        <li><a href="#errors">Notes</a></li>
                    </ul>
                </div>
            </div>
            <div className="reactive-column-container">
                <div className="reactive-column">
                    <Group label="Contact" id="contact">
                        <Row>
                            <Flex>
                                <LiveTextInput value={data.contactFirstName} fieldName="contactFirstName" label="First Name" update={update} onValidation={onValidation} />
                            </Flex>
                            <Flex>
                                <LiveTextInput value={data.contactLastName} fieldName="contactLastName" label="Last Name" update={update} onValidation={onValidation} />
                            </Flex>
                        </Row>
                        <Row>
                            <LiveTextInput value={data.contactStreetAddress} fieldName="contactStreetAddress" label="Street Address" update={update} onValidation={onValidation} />
                        </Row>
                        <Row>
                            <LiveTextInput value={data.contactCity} fieldName="contactCity" label="City" update={update} onValidation={onValidation} />
                            <LiveTextInput value={data.contactState} fieldName="contactState" label="State" width="5em" update={update} onValidation={onValidation} />
                            <LiveTextInput value={data.contactZipcode} fieldName="contactZipcode" label="Zipcode" width="8em" update={update} onValidation={onValidation} />
                        </Row>
                        <Row>
                            <LiveTextInput value={data.contactPhone} fieldName="contactPhone" label="Phone" width="30%" update={update} onValidation={onValidation} />
                            <LiveTextInput value={data.contactEmail} fieldName="contactEmail" label="Email" update={update} onValidation={onValidation} />
                        </Row>
                    </Group>
                    <Group label="Errors" id="errors">
                        {Object.keys(inputErrors).length > 0 ? <div>
                            <ol>
                                {Object.keys(inputErrors).map(e => <li>{inputErrors[e]}</li>)}
                            </ol>
                        </div> : <div><p>No errors</p></div>}
                    </Group>
                </div>
                <div className="reactive-column">
                    <Group label="Raw Case Data" id="rawdata">
                        <div style={{wordWrap: 'break-word'}}>{JSON.stringify(data)}</div>
                    </Group>
                </div>
            </div>
        </form>
    </div> : <LoadingOverlay />
}
