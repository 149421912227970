import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import tabsSlice from './tabs.slice';


const rootReducer = combineReducers({
    tabs: tabsSlice,
});

const persistedReducer = persistReducer({
    key: 'root',
    storage,
    whitelist: ['tabs']
}, rootReducer)


export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch