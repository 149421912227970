export const format = (fieldName: string, value: string) => {
    switch (fieldName) {
        case 'contactPhone':
            return phoneNumber(value);
        case 'contactZipcode':
            return zipcode(value);
        default:
            return value;
    }
}

function phoneNumber(value: string) {
    const numbers = value.replace(/[^0-9]/g, '');
    if (numbers.length >= 10) {
        return `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6, 10)}`;
    } else {
        return numbers;
    }
}

function zipcode(value: string) {
    const numbers = value.replace(/[^0-9]/g, '');
    if (numbers.length >= 5) {
        return numbers.substring(0, 5);
    } else {
        return numbers;
    }
}