import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tab, TabList, TabPanel, Tabs as ReactTabs} from "react-tabs";
import {CaseView} from "../components/CaseView";
import {Flex} from "../components/flex/Flex";
import {Icon} from "../components/Icon";
import {LoadingButton} from "../components/LoadingButton";
import {RootState, store} from "../store/store";
import {create, load, select, remove} from "../store/tabs.slice";
import '../styles/tabs.css';

export const Tabs = () => {
    const tabs = useSelector((state: RootState) => state.tabs);
    const selectedIndex = useSelector((state: RootState) => {
        if (!state.tabs.selected) return 0;
        const i = state.tabs.indexes.indexOf(state.tabs.selected);
        if (i < 0) return 0;
        return i;
    });

    const dispatch = useDispatch();
    return <ReactTabs selectedTabClassName="selected-tab" className="tabs-container" selectedIndex={selectedIndex} onSelect={(index) => {dispatch(select({index: tabs.indexes[index]}))}}>
        <div className="tablist">
            <TabList className="tabs-list">
                {tabs.indexes.map(index => <Tab key={index + '-item'} className="tab">
                    <div style={{display: 'flex', width: '100%', alignItems: 'center', gap: '.5em'}}>
                        <div> <Icon icon="folder" size={20} /></div>
                        <div style={{flexGrow: 1, textAlign: 'center'}}>
                            <div>{tabs.metadata[index].identifier}</div>
                            <div style={{color: 'gray', fontSize: '.8em'}}>
                                {tabs.metadata[index].date}
                            </div>
                        </div>
                        <div><Icon icon="x" onClick={(event) => {event.preventDefault(); event.stopPropagation(); dispatch(remove({index}))}} style={{color: 'olive', padding: '5px'}} size={20} /></div>
                    </div>
                    {/* <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: '.5em'}}>
                            <Icon icon="folder" size={20} /> <div>{tabs.metadata[index].identifier}</div> <Icon icon="x" onClick={(event) => {event.preventDefault(); event.stopPropagation(); dispatch(select({index}))}} style={{color: 'olive', padding: '5px'}} size={20} />
                        </div>
                        <div style={{color: 'gray', fontSize: '.8em', width: '100%', textAlign: 'center'}}>
                            {tabs.metadata[index].date}
                        </div>
                    </div> */}
                </Tab>)}
            </TabList>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderTop: '1px solid gray', marginTop: '2px'}}>
                <LoadingButton loading={tabs.loading} onClick={() => {store.dispatch(create())}}><Icon icon="plus-square" size={25} /></LoadingButton>
                <div style={{flexGrow: 1}}></div>
                <LoadingButton loading={tabs.loading} onClick={() => {store.dispatch(load('fBkhM5PeoakYMtM4FY68'))}}><Icon icon="download-cloud" size={25} /></LoadingButton>
            </div>
        </div>
        <div className="tabpanel">
            {tabs.indexes.map(index => <TabPanel key={index}>
                <CaseView caseId={index} />
            </TabPanel>)}
        </div>
    </ReactTabs>;
}