import {Link, NavLink, Outlet, useNavigate} from "react-router-dom";
import {getCurrentUser, signOut} from "../utilities/firebase";
import '../styles/dashboard.css';

export const Dashboard = () => {
    const navigate = useNavigate();
    return (<div id="dashboard">
        <div id="toolbar">
            <div className="toolbar-links">
                <NavLink className={({isActive}) => isActive ? 'selected-route' : ''} to="/dashboard/overview">Overview</NavLink>
                <NavLink className={({isActive}) => isActive ? 'selected-route' : ''} to="/dashboard/tabs">Tabs</NavLink>
                <NavLink className={({isActive}) => isActive ? 'selected-route' : ''} to="/dashboard/settings">Settings</NavLink>
            </div>
            <div className="spacer"></div>
            <div>{getCurrentUser()?.displayName ?? getCurrentUser()?.email ?? ''}</div>
            <div><button onClick={() => {signOut(); navigate('/login');}}>SIGN OUT</button></div>
        </div>
        <div id="content">
            <Outlet />
        </div>
    </div>);
}