import {FC, PropsWithChildren, ReactNode} from "react";
import {getCurrentUser} from "../utilities/firebase";
import {Navigate, useLocation} from 'react-router-dom';

export const RequireAuth = ({children}: PropsWithChildren) => {
    const location = useLocation();
    return getCurrentUser() ? (
        <>
            {children}
        </>
    ) : (
        <Navigate to="/login" replace state={{path: location.pathname}} />
    );
}
