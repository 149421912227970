import {KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {resetPassword, signIn} from "../utilities/firebase";
import "../styles/login.css"
import {EmailInput} from "../components/controls/Email";
import {PasswordInput} from "../components/controls/Password";
import {showAlert} from "../utilities/modals";

export const Login = ({path}: {path?: string}) => {
    const navigate = useNavigate();
    const emailRef = useRef<any>();
    const passwordRef = useRef<any>();
    useEffect(() => {emailRef.current?.focus()}, [])
    const [errors, setErrors] = useState<{[key: string]: string[]}>({});
    const errorMessages: string[] = useMemo(() => {
        return Object.keys(errors).reduce((acc: string[], field: string) => {
            acc.push(...errors[field]);
            return acc;
        }, [] as string[]);
    }, [errors])
    const reset = useCallback(() => {

        if (emailRef.current.isValid()) {
            resetPassword(emailRef.current.getValue()).then(() => {
                showAlert({type: 'success', message: 'Check your email (including junk folder) for the reset link.'}).catch(e => console.error(e));
            }).catch(e => {
                showAlert({type: 'error', message: 'Something went wrong. Unable to send reset link.'}).catch(e => console.error(e));
            });
        } else {
            showAlert({type: 'warning', message: 'You must enter a valid email before requesting a reset.'}).catch(e => console.error(e));
        }
    }, []);
    const login = useCallback(() => {
        if (emailRef.current.isValid() && passwordRef.current.isValid()) {
            signIn(emailRef.current.getValue(), passwordRef.current.getValue())
                .then(() => path ? navigate(path) : navigate('/home'))
                .catch(e => {
                    return showAlert({type: 'error', message: 'Login failed. Please try again.'});
                });
        }
    }, []);
    const onError = useCallback((name: string, errs: string[]) => {
        setErrors(errors => ({...errors, [name]: errs}));
    }, []);
    const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback((event) => {
        if (event.key === 'Enter') {
            login();
        }
    }, []);
    return (
        <div id="login" onKeyDown={onKeyDown}>
            <div className="logo"></div>
            <div id="login-controls">
                <div>
                    <EmailInput name="email" onError={onError} ref={emailRef} required />
                </div>
                <div>
                    <PasswordInput name="password" onError={onError} ref={passwordRef} required />
                </div>

                <div className="actions">
                    <div>
                        <button onClick={login}>Login</button>
                    </div>
                    <div className="reset">
                        <a href="#" onClick={reset}>Reset Password?</a>
                    </div>
                </div>
            </div>
            <div className="login-errors">
                {errorMessages.map(err => <div key={err}>{err}</div>)}
            </div>
        </div>
    );
}