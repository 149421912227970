import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import moment from 'moment';
import {createCase, loadCase} from '../utilities/firebase';
import {RootState} from './store';

interface TabsState {
    indexes: string[];
    metadata: {[key: string]: {identifier: string, date: string}};
    selected: string | null;
    loading: boolean;
};

const initialState: TabsState = {
    indexes: [],
    metadata: {

    },
    selected: null,
    loading: false
};

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        loading: (state, action: PayloadAction<void>) => {
            state.loading = true;
        },
        clearLoading: (state, action: PayloadAction<void>) => {
            state.loading = false;
        },
        select: (state, action: PayloadAction<{index: string}>) => {
            if (state.indexes.indexOf(action.payload.index) > -1) {
                state.selected = action.payload.index;
            }
            state.loading = false;
        },
        add: (state, action: PayloadAction<{index: string, metadata: any}>) => {
            if (state.indexes.indexOf(action.payload.index) < 0) {
                state.indexes = [action.payload.index, ...state.indexes];
            }
            state.metadata[action.payload.index] = action.payload.metadata;
            state.selected = action.payload.index;
            state.loading = false;
        },
        remove: (state, action: PayloadAction<{index: string}>) => {
            if (state.selected === action.payload.index) {
                state.selected = state.indexes[0] ?? null;
            }
            state.indexes = state.indexes.filter(i => i !== action.payload.index);
            delete state.metadata[action.payload.index];
            state.loading = false;

        },
        clear: (state) => {
            state.indexes = [];
            state.metadata = {};
            state.selected = null;
            state.loading = false;
        },
    },
});

export const create = () => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(loading());
        createCase().then((result) => {
            if (!result.data) throw new Error('Could not create case.');
            const {id, identifier, createdAt} = result.data as {id: string, identifier: string, createdAt: number};
            dispatch(add({index: id, metadata: {identifier, date: moment(createdAt).format('MM/DD/YYYY')}}));
        }).catch(e => {
            console.error(e);
            dispatch(clearLoading());
        });
    }
}

export const load = (index: string) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(loading());
        loadCase({caseId: index}).then((result) => {
            if (!result.data) throw new Error('Could not load case.');
            const {id, identifier, createdAt} = result.data as {id: string, identifier: string, createdAt: number};
            dispatch(add({index: id, metadata: {identifier, date: moment(createdAt).format('MM/DD/YYYY')}}));
        }).catch(e => {
            console.error(e);
            dispatch(clearLoading());
        });
    }
}

// Action creators are generated for each case reducer function
export const {add, remove, clear, loading, select, clearLoading} = tabsSlice.actions

export default tabsSlice.reducer