const colors = {
    'error': 'darkred',
    'warning': 'darkgray',
    'success': 'darkgreen'
}

const titles = {
    'error': 'Whoops...',
    'warning': 'Heads up...',
    'success': 'Eureka...'
}

export const AlertModal = ({onClose, message, type}: {onClose: () => void, message: string, type: 'error' | 'warning' | 'success'}) => {

    return <div className="modal" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,.4)'}}>
        <div style={{backgroundColor: 'white', color: 'black', padding: '2em', borderRadius: '2em', border: '4px solid lightblue', borderColor: colors[type]}}>
            <h2 style={{padding: 0, margin: 0}}>{titles[type]}</h2>
            <p>{message}</p>
            <div style={{width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                <button style={{display: 'block', backgroundColor: colors[type], margin: '.5em', borderRadius: '.5em'}} onClick={onClose}>Got it</button>
            </div>
        </div>
    </div>;
}