// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {signInWithEmailAndPassword, getAuth, User, setPersistence, browserLocalPersistence, sendPasswordResetEmail} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {} from "firebase/database";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {} from 'firebase/storage';
import {BehaviorSubject} from 'rxjs';
let user: User | null;

const firebaseConfig = {
    apiKey: "AIzaSyDHtvC4fKWrco53iyNvAf8F7Vb7D_BsJvM",
    authDomain: "apyre-2b6e1.firebaseapp.com",
    projectId: "apyre-2b6e1",
    storageBucket: "apyre-2b6e1.appspot.com",
    messagingSenderId: "467609480611",
    appId: "1:467609480611:web:7ada9472f4962f150e3e16",
    measurementId: "G-VJ5GMDG35S"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
export const ready$ = new BehaviorSubject(false);
export const db = getFirestore(app);
const functions = getFunctions(app);
export const createCase = httpsCallable(functions, 'createCase');
export const loadCase = httpsCallable(functions, 'loadCase');

auth.onAuthStateChanged(u => {
    user = u;
    ready$.next(true);
});

export const signIn = async (email: string, password: string) => {
    await auth.signOut();
    return signInWithEmailAndPassword(auth, email, password)
        .catch((error) => {
            throw error.code;
        });
}

export const signOut = () => auth.signOut();

export const getCurrentUser = () => user;

export const resetPassword = (email: string): Promise<void> => {
    //Verify email.
    return sendPasswordResetEmail(auth, email)
        .catch((error) => {
            throw error.code;
        });
}