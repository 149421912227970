import {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, redirect, Navigate} from 'react-router-dom';
import {ready$} from './utilities/firebase';
import {Login} from './routes/Login';
import {Dashboard} from './routes/Dashboard';
import {RequireAuth} from './components/RequireAuth';
import {filter} from 'rxjs';
import {Tabs} from './routes/Tabs';
import {Settings} from './routes/Settings';
import {Overview} from './routes/Overview';

function App() {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const sub = ready$.pipe(filter((val: boolean) => val)).subscribe(() => {setReady(true)});
    return () => {sub.unsubscribe()}
  })
  return ready ? (
    <Router>
      <Routes>
        <Route path='/dashboard' element={<RequireAuth><Dashboard /></RequireAuth>}>
          <Route path='overview' element={<Overview />} />
          <Route path='tabs' element={<Tabs />} />
          <Route path='settings' element={<Settings />} />
          <Route path='' element={<Navigate to="/dashboard/overview" replace />} />
          <Route path='*' element={<Navigate to="/dashboard/overview" replace />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route
          path="*"
          element={<Navigate to="/dashboard" replace />}
        />
      </Routes>
    </Router>
  ) : <div>Loading</div>;
}

export default App;
